export const Recommendation = {
    getEnhancementsData() {
        return {
            "data": {
                "enhancements": [
                    "Predictive Maintenance: FourSight",
                    "Generative AI: PostAI",
                    "Sentiment Analysis: CourseVibe"
                ],
                "explanations": [
                    "Implementing Predictive Maintenance through FourSight can help the company minimize downtime and optimize maintenance schedules, which is crucial for maintaining operational efficiency and reducing costs.",
                    "Utilizing Generative AI with PostAI can enhance the company's digital marketing strategies by automating content creation and scheduling, ensuring that the company maintains a consistent and engaging online presence tailored to its target audience.",
                    "Incorporating Sentiment Analysis via CourseVibe can provide valuable insights into user feedback, helping the company understand customer satisfaction and areas for improvement, ultimately enhancing the overall user experience."
                ],
                "sources": [
                    "https://aiminds.page",
                    "https://aiminds.page/about",
                    "https://aiminds.page/career",
                    "https://aiminds.page/",
                    "https://aiminds.page/contact",
                    "https://aiminds.page/privacy-policy",
                    "https://aiminds.page/portfolio"
                ]
            }
        }
    },

    getEnhancements() {
        return Promise.resolve(this.getEnhancementsData());
    },
}