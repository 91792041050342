import React from 'react';
import { Element } from 'react-scroll';
import AMSectionTitle from './AMSectionTitle';
import Slider from 'react-slick';

const BABenefits = () => {
  
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
  };
  const settingsMobile = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
  };
  
  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap ba-benefits" name="benefits-business-accelerator" id="BenefitsBusinessAccelerator">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-12 mt-0 p-0">
              <AMSectionTitle
                classes = "text-center"
                // slogan = "Introduction"
                title = "Benefit with our Expertise Across Key Industries"
              />
              <p className="ba-description mt-4 text-center">At M2M, we understand that every industry faces unique challenges and opportunities with AI. Our Business Accelerator offers tailored solutions that address the specific needs of diverse sectors, helping you unlock AI's full potential where it matters most.</p>
            </div>
            <div className="col-12 mt--40">
              <Slider {...settings} className="testimonial-activation edu-slick-button slick-button-center d-none d-md-block">
                <div>
                  <div className="ba-benefits-card ba-benefits-card0">
                    <p className="ba-benefits-card-content">FinTech</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card1">
                    <p className="ba-benefits-card-content">Clean<br></br>Technology</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card2">
                    <p className="ba-benefits-card-content">IoT, 5G &<br></br>Cybersecurity</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card3">
                    <p className="ba-benefits-card-content">Advanced<br></br>Manufacturing</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card4">
                    <p className="ba-benefits-card-content">Business<br></br>Intelligence</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card5">
                    <p className="ba-benefits-card-content">Health<br></br>Technology</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card6">
                    <p className="ba-benefits-card-content">AgTech</p>
                  </div>
                </div>
              </Slider>
              <Slider {...settingsMobile} className="testimonial-activation edu-slick-button slick-button-center d-block d-md-none">
                <div>
                  <div className="ba-benefits-card ba-benefits-card1">
                    <p className="ba-benefits-card-content">Clean<br></br>Technology</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card2">
                    <p className="ba-benefits-card-content">IoT, 5G &<br></br>Cybersecurity</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card3">
                    <p className="ba-benefits-card-content">Advanced<br></br>Manufacturing</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card4">
                    <p className="ba-benefits-card-content">Business<br></br>Intelligence</p>
                  </div>
                </div>
                <div>
                  <div className="ba-benefits-card ba-benefits-card5">
                    <p className="ba-benefits-card-content">Health<br></br>Technology</p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BABenefits;