import React from 'react';
import { Element } from 'react-scroll';
import AMSectionTitle from './AMSectionTitle';
import { scrollToViewFromTop } from '../../utils/scrollToViewFromTop';

const BAProgramOverview = ({
  targetRefBA,
  innerRefBA,
  showTalentPipeline,
  showMentorshipCoaching,
  handleTalentPipelineClick,
  handleMentorshipCoachingClick
}) => {

  return (
    <>
      <Element
        name="ProgramOverview"
        className="am-program-overiew datatalent-section-gap"
        id="ProgramOverview"
      >
        <div className="container inner" ref={targetRefBA}>
          <div className="row g-5 align-items-center datatalent-showcases">
            <div className="col-lg-12 p-0 mt-0">
              <AMSectionTitle
                classes = "text-left"
                // slogan = "Program Overview"
                title = "Workforce Solutions and Expert Coaching in One Place"
              />
            </div>
            <div className="col-lg-12 mt-0 p-0">
              <div className="dpo-nav d-flex flex-row gap-3 me-0" ref={innerRefBA}>
                <div>
                  <button className="dpo-nav-item active-program-overview" id="TalentPipeline" onClick={handleTalentPipelineClick}>
                    Talent Pipeline
                  </button>
                </div>
                <div>
                  <button className="dpo-nav-item" id="MentorshipCoaching" onClick={handleMentorshipCoachingClick}>
                    Mentorship & Coaching
                  </button>
                </div>
              </div>
            </div>
            {showTalentPipeline && <>
              <div className="p-0 row g-5">
                <div className="col-12 col-lg-5">
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/workforce_visual.png`} alt="illustration" />
                </div>
                <div className="col-12 col-lg-7">
                  <h4>Tap Into a Skilled Talent Pool for Real-World AI Projects</h4>
                  <p>
                    At M2M, <strong>workforce development</strong> is at the core of what we do. Through our federally funded DataTalent Program and Work-Integrated Learning (WIL) initiatives, we provide businesses with access to industry-ready professionals who bring innovation to real-world AI projects. 
                  </p>
                  <span style={{color: "var(--color-primary)"}}>FEATURES</span>
                  <div className="d-flex flex-row mt-4">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/business_model.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Access to Talent</h5>
                      <p>Collaborate with Data Scientists, ML Engineers, and AI professionals from M2M, assigned to your team for 4-5 months.</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/funding_strategy.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Project Management Support</h5>
                      <p>Ensure your projects align with industry standards through guidance from experienced M2M Tech Leads.</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/regulatory_compliance.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Diverse Talent across Industries</h5>
                      <p>Talent skilled in Advanced Manufacturing, CleanTech, HealthTech, and Digital Technology.</p>
                    </div>
                  </div>
                </div>
              </div>
            </>}
            {showMentorshipCoaching && <>
              <div className="p-0 row g-5">
                <div className="col-12 col-lg-5">
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/workforce_visual.png`} alt="illustration" />
                </div>
                <div className="col-12 col-lg-7">
                  <h4>Guidance from AI and Business Strategy Experts</h4>
                  <p>
                    M2M's Business Accelerator connects you with seasoned mentors who bring are AI Implementation and Technology Development Experts. Our goal is to help you build a sustainable, scalable business mode
                  </p>
                  <span style={{color: "var(--color-primary)"}}>FEATURES</span>
                  <div className="d-flex flex-row mt-4">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/business_model.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Personalized Mentorship</h5>
                      <p>Work with industry leaders who have successfully implemented and scaled AI-driven solutions in your business sector.</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/funding_strategy.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Leadership Coaching</h5>
                      <p>Learn to manage AI projects effectively, lead tech teams, and make strategic decisions that drive growth beyond your time in the AI Ready program.</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/regulatory_compliance.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Access to Industry Experts</h5>
                      <p>Tap into M2M’s vast network of AI specialists and technical advisors for guidance at every stage of your journey.</p>
                    </div>
                  </div>
                </div>
              </div>
            </>}
          </div>
        </div>
      </Element>
    </>
  )
}

export default BAProgramOverview;