import React, { useRef } from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerOne from '../../components/banner/BannerOne';
import HomeOne12WeeksProgram from '../../components/home-one/HomeOne12WeeksProgram';
import HomeOneWhatWeDo from '../../components/home-one/HomeOneWhatWeDo';
import HomeOneHowWeDoIt from '../../components/home-one/HomeOneHowWeDoIt';
import HomeOneWhatYouWillLearn from '../../components/home-one/HomeOneWhatYouWillLearn';
import HomeOnePathsYouCanFollow from '../../components/home-one/HomeOnePathsYouCanFollow';
import HomeOneSuccessStory from '../../components/home-one/HomeOneSuccessStory';
import HomeOneCategory from '../../components/home-one/HomeOneCategory';
// import HomeOneCourses from '../../components/home-one/HomeOneCourses';
import TestimonialSectionFour from '../../components/testimonial-section/TestimonialSectionFour';
import VideoSectionTwo from '../../components/video-section/VideoSectionTwo';
import NewsLetterOne from '../../components/newsletter/NewsLetterOne';
import FooterOne from '../../common/footer/FooterOne';
import HomeOneSolutions from '../../components/home-one/HomeOneSolutions';
import HomeOneStudentsSpotlightBlogs from '../../components/home-one/HomeOneStudentsSpotlightBlogs';
import RegisterProgramForm from '../../components/form/register-program-form/RegisterProgramForm';
import UserInterestForm from '../../components/form/user-interest-form/UserInterestForm';
import SkillsboostSignUpForm from '../../components/form/skillsboost-form/SkillsboostSignUpForm';
import HomeOneAbout from '../../components/home-one/HomeOneAbout';
import HomeOneIndustriesWeServe from '../../components/home-one/HomeOneIndustriesWeServe';
import HomeOneOurApproach from '../../components/home-one/HomeOneOurApproach';
import HomeOneContactUs from '../../components/home-one/HomeOneContactUs';
import HomeOnePrograms from '../../components/home-one/HomeOnePrograms';
import HomeOneAIRecommendations from '../../components/home-one/HomeOneAIRecommendations';
import { useLocation } from 'react-router-dom';
import { scrollToViewFromTop } from '../../utils/scrollToViewFromTop';

const HomeOne = ({
  showUserInterestForm,
  setShowUserInterestForm,
  showRegisterForm,
  setShowRegisterForm,
  showSkillsboostSignUpForm,
  setShowSkillsboostSignUpForm,
  setFilterValue
  }) => {

  const GoogleReviews = useRef();

  const location = useLocation();
  if(location.hash) {
    const sectionId = location.hash.slice(1);
    console.log(sectionId);
    scrollToViewFromTop(sectionId);
  }

  return (
    <>
      <SEO
        title="M2M Tech"
        description="Join a cohort and revolutionize your learning experience where collaboration, community and personalization meet!"
      />

      <HeaderTwo styles="header-transparent header-style-2" searchDisable />

      <BannerOne
        setShowUserInterestForm={setShowUserInterestForm}
        GoogleReviews={GoogleReviews}
        setFilterValue={setFilterValue}
      />

      {/* <HomeOneAbout /> */}

      <HomeOneAIRecommendations />
        
      <HomeOneSolutions />

      <HomeOnePrograms />

      <HomeOneIndustriesWeServe />

      <HomeOneOurApproach />

      {/* <HomeOne12WeeksProgram /> */}

      {/* <HomeOneWhatWeDo /> */}

      {/* <HomeOneHowWeDoIt /> */}

      {/* <HomeOneWhatYouWillLearn /> */}

      {/* <HomeOnePathsYouCanFollow /> */}

      {/* <HomeOneCategory /> */}

      {/* <HomeOneCourses /> */}

      {/* <VideoSectionTwo /> */}

      {/* <HomeOneStudentsSpotlightBlogs /> */}

      {/* <HomeOneSuccessStory /> */}

      <TestimonialSectionFour GoogleReviews={GoogleReviews} />

      <HomeOneContactUs />

      <NewsLetterOne />

      <FooterOne />

      {showUserInterestForm && <UserInterestForm setShowUserInterestForm={setShowUserInterestForm} />}
      {/* {showRegisterForm && <RegisterProgramForm setShowRegisterForm={setShowRegisterForm} />} */}
      {showSkillsboostSignUpForm && <SkillsboostSignUpForm setShowSkillsboostSignUpForm={setShowSkillsboostSignUpForm} />}
    </>
  )
}

export default HomeOne;