import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import FsLightbox from 'fslightbox-react';
import { Element } from 'react-scroll';
import { FaPlay } from 'react-icons/fa';
import { scrollToViewFromTop } from '../../utils/scrollToViewFromTop';
import { scrollToViewFromBottom } from '../../utils/scrollToViewFromBottom';
import { Recommendation } from './Recommendation';
import { Link as ScrollTo } from 'react-scroll';
import AMSectionTitle from './AMSectionTitle';

const BABanner2 = () => {
  const [toggler, setToggler] = useState(false);
  const [url, setUrl] = useState("");
	const [showResultsSection, setShowResultsSection] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
  const [error, setError] = useState("");

  const videoLink = ['https://www.youtube.com/embed/lSi2H_ULp4s?autoplay=1&rel=0'];

  const changeUI = () => {
    if(!error) {
      setLoading(true);
      setShowResultsSection(true);
      scrollToViewFromBottom("AIAccelerationResults");
      // setShowResults(false);
      // const el = document.getElementById("AIAcceleration");
      // el.classList.add("ai-acceleration-results");
      // setTimeout(() => {
      // 	setShowAIAccelerationForm(true);
      // }, 3000);
    } else {

    }
	}

  const handleEnhancementFormSubmit = (e) => {
		e.preventDefault();
    setError("");
		// console.log("Form submitted.");
		// console.log("Fetching response from API.");
    // setError(error => error + "Some Error!");
		changeUI();
		// fetch(process.env.REACT_APP_AI_ENHANCEMENTS, {
		// 	method: "POST",
		// 	headers: {
		// 		"Content-Type" : "application/json"
		// 	},
		// 	body: JSON.stringify( {"url": url, "deep" : false} )
		// })
		// .then(res => res.json())
		// .then(recommendations => {
		// 	setLoading(false);
		// 	return (setData(recommendations.data))
		// })
    // .catch(error => setError(error));
		setTimeout(() => {
			Recommendation.getEnhancements().then(data => setData(data.data));
			setLoading(false);
		}, 5000);
	}

  return (
    <>
    <Element
      name="datatalent-banner"
      className="data-talent-jobseekers-banner am-banner banner-style-1 bg-image height-640 d-flex align-items-center"
    >
      <div className="container eduvibe-animated-shape">
        <div className="row g-5 align-items-center">
          <div className="order-1 order-xl-1 col-lg-12 col-xl-6 banner-left-content">
            <div className="inner">
              <div className="content position-relative">
                <div className="datatalent-jobseekers-breadcrumb d-flex align-items-center">
                  <Link to="/programs">Our Programs</Link>
                  <i className="ri-arrow-drop-right-line"></i>
                  Business Accelerator
                </div>
                <h1 className="datatalent-program-title title mt-4">Integrate AI Into Your Business with Business Accelerator</h1>
                <p className="description">Unleash the potential of AI to transform your business. M2M's Business Accelerator provides the tools, expertise, and network you need to innovate, scale, and thrive in the age of AI.</p>
                {/* <span className="datatalent-description-span">*eligibility requirements below</span> */}
                <div className="read-more-btn banner-btns">
                  {/* <button className="edu-btn" onClick={() => setShowEmployerRegisterForm(true)}>Employer Login</button>
                  <button className="edu-btn btn-secondary" onClick={() => setShowUserInterestForm(true)}>Jobseekers Login</button> */}
                  <div className="col-6 col-lg-4">
                    <button className="edu-btn btn-primary text-center w-100" onClick={() => scrollToViewFromTop("AboutBusinessAccelerator")}>Apply Now</button>
                  </div>
                  <div className="col-6 col-lg-4 d-flex justify-content-end">
                    <Link className="edu-btn btn-bg-alt text-center" to="/contact-us">Contact Us</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-2 order-xl-3 col-lg-12 col-xl-6 d-flex flex-column align-items-center justify-content-center banner-right-content mt--60">
            {/* <div className="feature-thumbnail">
              <div className="main-image video-popup-wrapper video-popup-two">
                <img src={`${process.env.PUBLIC_URL}/images/videopopup/pexels-monstera-6238170_v2.png`} alt="Video PopUp Thumb" loading="lazy" />

                <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={ () => setToggler( ! toggler ) }><span><FaPlay className="play-icon" /></span></button>
              </div>
            </div> */}
            <div className="ba-ai-accelerator-tool">
              <h4>Your AI Integration Assistant</h4>
              <p>Enter your company website URL, and our AI tool will scan your site to suggest the top 3 AI integrations tailored to your business needs.</p>
              <form onSubmit={handleEnhancementFormSubmit}>
								<div className="d-flex flex-row gap-4">
									<input type="text" className="bg-white" placeholder="Enter your website url" value={url} onChange={(e) => setUrl(e.target.value)} required />
									<button type="submit" className="edu-btn btn-secondary">Submit</button>
								</div>
							</form>
							<small><strong>Disclaimer:</strong> Our AI tool will scrape your website. By submitting this form you agree to allow us to scrape your website.</small>
            </div>
            <p className="col-12 align-self-start datatalent-partners-header mt-5 mb-0 ps-5"><strong>Partnered with</strong>:</p>
            <div className="col-12 datatalent-partners align-items-center mt-5 ps-5">
              <div className="col-lg-3">
                <a className="p-4 bg-white" href="https://www.scaleai.ca/"><img src={`${process.env.PUBLIC_URL}/images/logo/scale_ai_logo.png`} alt="logo" loading="lazy" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="video-lightbox-wrapper">
          <FsLightbox
            toggler={ toggler } 
            sources={ [ <iframe src={videoLink} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
            maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
          />
        </div>
      </div>
    </Element>
    {showResultsSection && <Element
      name="ai-accelerator"
      className="ai-accelerator-results edu-section-gap" id="AIAccelerationResults">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-12">
              <AMSectionTitle
                classes = "text-center text-white"
                // slogan = "Introduction"
                title = "Top AI Enhancements Recommendations"
              />
            </div>
          {showResultsSection && <div className="col-12">
							{loading && <div className="text-center">
								{/* <img src={process.env.PUBLIC_URL+"/images/loading.gif"} alt="loader" /> */}
								<img src={process.env.PUBLIC_URL+"/images/loading_pulse.gif"} alt="loader" />
								{/* <img src={process.env.PUBLIC_URL+"/images/loading3_transparent.gif"} alt="loader" /> */}
							</div>}
							{loading ? <div className="col-12 mt-60">
								<div className="row gx-4 gy-5 justify-content-center">
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card p-4 position-relative">
											{/* <div className="enhancement-number position-absolute">1.</div> */}
											<div className="aiac-shimmer-heading"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body2"></div>
											<div className="d-flex flex-row justify-content-between">
												<div className="aiac-shimmer-cta"></div>
												<div className="aiac-shimmer-cta"></div>
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card ai-acceleration-card-2 p-4 position-relative">
											{/* <div className="enhancement-number position-absolute">2.</div> */}
											<div className="aiac-shimmer-heading"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body2"></div>
											<div className="d-flex flex-row justify-content-between">
												<div className="aiac-shimmer-cta"></div>
												<div className="aiac-shimmer-cta"></div>
											</div>
										</div>
									</div>	
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card p-4 position-relative">
											{/* <div className="enhancement-number position-absolute">3.</div> */}
											<div className="aiac-shimmer-heading"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body1"></div>
											<div className="aiac-shimmer-body2"></div>
											<div className="d-flex flex-row justify-content-between">
												<div className="aiac-shimmer-cta"></div>
												<div className="aiac-shimmer-cta"></div>
											</div>
										</div>
									</div>
								</div>
							</div> : 
              error ? <p className="ai-error">{error} Please try a different URL.</p> : <div className="col-12 mt-5">
								<div className="row gx-4 gy-5 justify-content-center">
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card p-4 position-relative">
											<div className="enhancement-number position-absolute">1.</div>
											<h6 className="text-center mt-4">{data.enhancements[0]}</h6>
											<p>{data.explanations[0]}</p>
											<div className="d-flex flex-row justify-content-between">
                        <ScrollTo
                          to="about-business-accelerator"
                          spy={true}
                          smooth={true}
                          duration={200}
                        >
												  <button className="edu-btn btn-medium btn-bg-alt mt-4">Learn More</button>
                        </ScrollTo>
												<Link to="/contact-us" className="edu-btn btn-secondary btn-medium mt-4">Schedule Call</Link>
											</div>
										</div>
									</div>
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card ai-acceleration-card-2 p-4 position-relative">
											<div className="enhancement-number position-absolute">2.</div>
											<h6 className="text-center mt-4">{data.enhancements[1]}</h6>
											<p>{data.explanations[1]}</p>
											<div className="d-flex flex-row justify-content-between">
												<ScrollTo
                          to="about-business-accelerator"
                          spy={true}
                          smooth={true}
                          duration={200}
                        >
												  <button to="/coming-soon" className="edu-btn btn-medium btn-bg-alt mt-4">Learn More</button>
                        </ScrollTo>
												<Link to="/contact-us" className="edu-btn btn-secondary btn-medium mt-4">Schedule Call</Link>
											</div>
										</div>
									</div>	
									<div className="col-12 col-lg-4">
										<div className="ai-acceleration-card p-4 position-relative">
											<div className="enhancement-number position-absolute">3.</div>
											<h6 className="text-center mt-4">{data.enhancements[2]}</h6>
											<p>{data.explanations[2]}</p>
											<div className="d-flex flex-row justify-content-between">
												<ScrollTo
                          to="about-business-accelerator"
                          spy={true}
                          smooth={true}
                          duration={200}
                        >
												  <button to="/coming-soon" className="edu-btn btn-medium btn-bg-alt mt-4">Learn More</button>
                        </ScrollTo>
												<Link to="/contact-us" className="edu-btn btn-secondary btn-medium mt-4">Schedule Call</Link>
											</div>
										</div>
									</div>

									<div className="col-12">
										<p className="ai-acceleration-footer-note"><strong>Note:</strong> These results are shown based only on the homepage of the entered url.</p>
										{/* <p>Click the "deep dive" button below to get AI Enhancement recommendations based on complete website analysis.</p>
										{deepDiveEnable && <button className="edu-btn btn secondary" onClick={handleDeepDive}>Deep Dive</button>} */}
									</div>
								</div>
							</div>}
						</div>}
          </div>
        </div>
    </Element>}
    </>
  )
}

export default BABanner2;