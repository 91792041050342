import React from 'react';
import { Element } from 'react-scroll';
import AMSectionTitle from './AMSectionTitle';
import { scrollToViewFromTop } from '../../utils/scrollToViewFromTop';

const BAComprehensive = ({
  targetRefBAC,
  innerRefBAC,
  showBusinessCoachingAdvisory,
  showFundingResources,
  handleBusinessCoachingAdvisoryClick,
  handleFundingResourcesClick
}) => {

  return (
    <>
      <Element
        name="ProgramOverview"
        className="am-program-overiew datatalent-section-gap"
        id="ProgramOverview"
      >
        <div className="container inner" ref={targetRefBAC}>
          <div className="row g-5 align-items-center datatalent-showcases">
            <div className="col-lg-12 p-0 mt-0">
              <AMSectionTitle
                classes = "text-left"
                // slogan = "Program Overview"
                title = "Comprehensive Business Support: Coaching, Advisory, and Resources"
              />
              <p className="ba-comprehensive-description">M2M's AI Ready for businesses offers more than just expertise. We provide access to valuable funding opportunities, technical resources, and hands-on guidance, empowering you to explore AI with minimized risks and maximized potential.</p>
            </div>
            <div className="col-lg-12 mt-0 p-0">
              <div className="dpo-nav d-flex flex-row gap-3 me-0" ref={innerRefBAC}>
                <div>
                  <button className="dpo-nav-item active-program-overview" id="BusinessCoachingAdvisory" onClick={handleBusinessCoachingAdvisoryClick}>
                    Business Coaching & Advisory
                  </button>
                </div>
                <div>
                  <button className="dpo-nav-item" id="FundingResources" onClick={handleFundingResourcesClick}>
                    Funding & Resources
                  </button>
                </div>
              </div>
            </div>
            {showBusinessCoachingAdvisory && <>
              <div className="p-0 row g-5">
                <div className="col-12 col-lg-7">
                  <h4>Business Coaching & Advisory</h4>
                  <p>
                    We provide expert guidance to help you build a sustainable and scalable business while staying compliant with industry standards.
                  </p>
                  <span style={{color: "var(--color-primary)"}}>FEATURES</span>
                  <div className="d-flex flex-row mt-4">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/business_model.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Business Model Validation</h5>
                      <p>Ensure your AI-driven business model is viable and scalable.</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/funding_strategy.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Funding Strategy</h5>
                      <p>Access grants, venture capital, and funding opportunities tailored to tech startups.</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/regulatory_compliance.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Regulatory Compliance</h5>
                      <p>Stay compliant with data privacy laws and AI ethics standards.</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/business_resources.png`} alt="illustration" />
                </div>
              </div>
            </>}
            {showFundingResources && <>
              <div className="p-0 row g-5">
                <div className="col-12 col-lg-7">
                  <h4>Funding & Resources</h4>
                  <p>
                    Our accelerator reduces the costs and risks of AI projects, offering funding, mentorship, and infrastructure support for sustainable success.
                  </p>
                  <span style={{color: "var(--color-primary)"}}>FEATURES</span>
                  <div className="d-flex flex-row mt-4">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/business_model.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Initial Funded Support</h5>
                      <p>Benefit from 4-5 months of collaboration with M2M's talent pool and professional team, fully covered to help guide and align your AI strategy.</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/funding_strategy.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Success-Based Funding</h5>
                      <p>Eligible projects may qualify for a second round of funding with up to $50,000 CAD to commercialize your product.</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="col-1 mt-4">
                      <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/regulatory_compliance.png`} alt="illustration" />
                    </div>
                    <div className="col-11">
                      <h5 className="mb-3">Funding Guidance</h5>
                      <p>Expert support to help secure funding opportunities tailored to your project's needs.</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/business_resources.png`} alt="illustration" />
                </div>
              </div>
            </>}
          </div>
        </div>
      </Element>
    </>
  )
}

export default BAComprehensive;