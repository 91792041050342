import React from 'react'
import AMSectionTitle from './AMSectionTitle';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';


const BAPerfectFor = () => {
  return (
    <>
      <Element
        name="PerfectFor"
        className="ba-perfect-for datatalent-section-gap"
        id="PerfectFor"
      >
        <div className="container inner">
          <div className="row g-5 align-items-center">
            <div className="col-12 p-0 mt-0">
              <AMSectionTitle
                classes = "text-center"
                // slogan = "Program Overview"
                title = "Our AI Business Accelerator is perfect for"
              />
            </div>
            <div className="ba-perfect-for-card-section col-12 row g-4">
              <div className="col-12 col-lg-6">
                <div className="ba-perfect-for-card ba-perfect-for-card-cream h-100 d-flex flex-column justify-content-between">
                  <div className="ba-perfect-for-card-content">
                    <h5 className="mb-3">Startups</h5>
                    <p>Explore AI use cases and develop your first product.</p>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/startups.png`} alt="illustration" />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="ba-perfect-for-card ba-perfect-for-card-white h-100 d-flex flex-column justify-content-between">
                  <div className="ba-perfect-for-card-content">
                    <h5 className="mb-3">Established Businesses</h5>
                    <p>Innovate with AI in sectors like FinTech, Healthcare, Retail, Energy, and Sustainability.</p>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/established_businesses.png`} alt="illustration" />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="ba-perfect-for-card ba-perfect-for-card-cream h-100 d-flex flex-column justify-content-between">
                  <div className="ba-perfect-for-card-content">
                    <h5 className="mb-3">Subject Matter Expert's (SMEs)</h5>
                    <p>Scale AI capabilities and streamline operations</p>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/smes.png`} alt="illustration" />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="ba-perfect-for-card ba-perfect-for-card-white h-100 d-flex flex-column justify-content-between">
                  <div className="ba-perfect-for-card-content">
                    <h5 className="mb-3">Non-Tech Companies</h5>
                    <p>Streamline operations and improve efficiency through AI.</p>
                  </div>
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/non-tech.png`} alt="illustration" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BAPerfectFor;