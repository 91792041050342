import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../sectionTitle/SectionTitle';
import ScrollAnimation from 'react-animate-on-scroll';
import { Element } from 'react-scroll';

const HomeOneAIRecommendations = () => {
	return (
		<>
			<Element name="ai-acceleration" className="ai-acceleration-area edu-about-area about-style-1 edu-section-gap" id="AIAcceleration">
				<div className="container">
					<div className="row g-5">
						<div className="col-12">
							<SectionTitle
								classes = "text-white text-center"
								slogan = "Find AI Recommendations"
								title = "Get Instant AI Integration Suggestions For Your Business"
							/>
						</div>
						<div className="col-12 text-center">
							<form action="">
								<div className="d-flex flex-row gap-4">
									<input type="text" className="bg-white" placeholder="Enter your website url" />
									<button type="submit" className="edu-btn btn-secondary">Submit</button>
								</div>
							</form>
							<small className="text-white"><strong>Disclaimer:</strong> Our AI tool will scrape your website. By submitting this form you agree to allow us to scrape your website.</small>
						</div>
					</div>          
				</div>
			</Element>
		</>
	)
}

export default HomeOneAIRecommendations;