import React from 'react';
import { Element } from 'react-scroll';

const BASupport = () => {

  return (
    <>
      <Element className="ba-support edu-about-area bg-white datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-12 mt-0 p-0">
              <div className="ba-support-wrapper mx-md-5 d-flex flex-row flex-wrap">
                <div className="col-12 col-md-8 p-5">
                  <h4>What is the Business Accelerator?</h4>
                  <h5 className="d-none d-md-block">M2M's Business Accelerator supports businesses in integrating AI into their products and workplace. Our program provides technical expertise and a dedicated team to bring your AI vision to life.</h5>
                  <p className="d-block d-md-none">M2M's Business Accelerator supports businesses in integrating AI into their products and workplace. Our program provides technical expertise and a dedicated team to bring your AI vision to life.</p>
                  <div className="ba-support-bullets d-flex flex-row flex-wrap">
                    <div className="col-12 col-lg-6 d-flex flex-row">
                      <div className="col-1">
                        <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/green_check.svg`} alt="illustration" />
                      </div>
                      <div className="col-11 ms-4">
                        <h5 className="mb-2">Technical Expertise</h5>
                        <p className="mb-0">Access to a team of AI professionals skilled in industry-specific applications.</p>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 d-flex flex-row">
                      <div className="col-1">
                        <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/green_check.svg`} alt="illustration" />
                      </div>
                      <div className="col-11 ms-4">
                        <h5 className="mb-2">Professional Team</h5>
                        <p className="mb-0">A dedicated team to collaborate with you through the AI prototype phase.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 p-4">
                  <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/highlight_section.png`} alt="illustration" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default BASupport;